import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "customers",
  "title": "Customers",
  "customerSection": [{
    "size": 30,
    "logo": "/img/zimply-text-black.png",
    "path": "case/svea-bank"
  }],
  "seo": {
    "title": "-",
    "descr": "-"
  },
  "customerList": [{
    "size": 20,
    "logo": "/img/svea.webp",
    "path": "case/svea-bank"
  }, {
    "size": 25,
    "logo": "/img/dina.webp",
    "path": "case/dina"
  }, {
    "size": 30,
    "path": "case/sbb",
    "logo": "/img/zimply_sbb_logo.webp"
  }, {
    "size": 10,
    "path": "case/acne-studios",
    "logo": "/img/acne.webp"
  }, {
    "size": 22,
    "path": "case/wise-professionals",
    "logo": "/img/wise.webp"
  }, {
    "size": 12,
    "logo": "/img/futur2.webp",
    "path": "case/futur-pension"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      